<template>
  <div>
    <div class="DivBox">
      <p class="PTitle">体系数字化分工</p>
    </div>
    <div class="DivBox">
      <p class="PTitle LeftBor">服务介绍：</p>
      <p class="SmallP" style="text-indent: 2em">
        最合理的分工是在过程中进行，过程有6个要素，过程分工就有6个方面，如图所示：
      </p>
      <img
        @click="openWindow"
        src="@/assets/FirstPage-ProductSon/24.png"
        class="SonImg"
        style="height: auto"
      />
      <p class="SmallP" style="text-indent: 2em">
        上图为过程分工模型。在过程中分工是最全面、最彻底的和最精准的，也是最科学的，并且能确保专业人士做专业的事。系统越大，层级就越多，过程就越多，将分工全部转移到无数的过程中的6个要素上去，复杂性就被很多个体分解。分工的本质就是自己掌握主动权。根据分工原理，分工越细工作就越简单、效率就越高，就能发现资源的最佳配置。在数字化体系中，分工的实际界面如下：
      </p>
      <br />
      <img
        @click="openWindow"
        src="@/assets/FirstPage-ProductSon/25.png"
        class="SonImgW"
        style="width: 800px"
      />
      <br />
      <br />
      <p class="SmallP" style="padding: 2px 0; text-indent: 2em">
        每个过程都完成了6要素的分工后，依照这种方法，就会发现岗位的活动量会有几十个、几百个甚至上千个。当数字化后，系统会自动识别每个岗位所有的工作内容，马上知道，传统的《岗位职责说明书》所列的活动量与之相比只是冰山一角，不能代表员工真正的工作量，这种方法就可以取代传《岗位职责说明书》了。
      </p>
      <p class="SmallP" style="padding: 2px 0; text-indent: 2em">
        在系统中还可以显示各个岗位活动的分布图，尤其是关键岗位的活动占比，就知道如何给岗位加权，人力资源的重心应该放在哪里。结果一出，这时，组织便会迫不及待地检讨现时的组织结构和岗位的配置，就会有惊喜的发现。有了活动的大数据对提升人效和精简人员就有了科学的依据。
      </p>
      <p class="SmallP" style="padding: 2px 0; text-indent: 2em">
        系统还可以进一步将每个岗位的分工细化到每天、每周、每月做什么，并显示在员工的工作看板上，为体系落地打下了坚实的基础。下图是活动以月为周期的例子：
      </p>
      <img
        @click="openWindow"
        src="@/assets/FirstPage-ProductSon/26.png"
        class="SonImgW"
        style="width: 800px"
      />
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    openWindow() {
      window.open("https://www.yuntixi.cn", "_blank");
    },
  },
};
</script>

<style>
@import "../../../../assets/style/FirstProductSon.css";
</style>